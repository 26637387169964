
import { QTabs, QTab } from "quasar"
import PrismicDOM from "prismic-dom";

export default {
    name: "Durability",
    components: {
        QTabs,
        QTab
    },
    data() {
        return {
            lang: this.$router.currentRoute.params.lang,
            selectedTab: 0,
            tabList: null,
        }
    },
    computed: {
        renderedMarkdownContent() {
            if (this.tabList && this.tabList[this.selectedTab]) {
                return PrismicDOM.RichText.asHtml(this.tabList[this.selectedTab].markdown_content);
            }
            return '';
        }
    },
    mounted() {
        this.getContent()
    },
    methods: {
        async getContent() {
            const { results } = await this.$prismic.client.query([
                this.$prismic.Predicates.at("document.type", "durability"),
                this.$prismic.Predicates.at("document.tags", [this.lang]),
            ]);

            const data = results[0].data;
            this.tabList = data.tabs;

            console.log("results", this.tabList);
        },
    }
}
